import React from "react";
import '../assets/css/style.css'

import LoadingOverlay from 'react-loading-overlay';
import * as Constant from './Constant'
import './contact.css'
import contact_img from '../assets/img/subjects/contact_img.png'
import ReCAPTCHA from 'react-google-recaptcha';

class ContactUs extends React.Component {

  constructor() {
    super();
    
    const params = new URLSearchParams(window.location.search);
    const subjectId = params.get("subject_id") || '';
    this.state = {
      name: '',
      email: '',
      number: '',
      level_name: '',
      message: '',
      what_we_help: '',
      isLoading: false,

      nameError: null,
      emailError: null,
      numberError: null,
      messageError: null,
      what_we_helpError: null,
      isActive: false,
      captchaText: '',
      userInput: '',
      isCaptchaValid: false,
      captchError: null,
      num1: Math.floor(Math.random() * 10) + 1,
      num2: Math.floor(Math.random() * 10) + 1,
      answer: '',
      isCorret: '',
      SubjectList: [],   // Assuming SubjectList is set via an API call
      isLoading: false,
      subject_id: '',  // Get subject_id from URL params
    }

    this.recaptchaRef = React.createRef();
  }

  handleUserInput = (event) => {
    this.setState({ userInput: event.target.value })
  };
  componentDidMount = () => {
    const params = new URLSearchParams(window.location.search);
    const subjectId = params.get("subject_id") || '';  // Default to an empty string if not found

    if (subjectId) {
      // Set the subject_id in the state and call getSubject after the state is updated
      this.setState({ subject_id: subjectId }, () => {
        console.log("Subject ID from URL:", this.state.subject_id);  // Debugging log
        this.getSubject();  // Fetch SubjectList after setting subject_id
      });
    } else {
      console.log('No subject_id found in URL params');
    }
  }

  getSubject = async () => {
    // Fetch subjects from the API
    await fetch(Constant.getSubject, {
        method: "GET"
    })
        .then(Response => Response.json())
        .then(data => {
            if (data.success === true) {
                // Update state with fetched subjects
                this.setState({
                    isLoading: false,
                    SubjectList: data.data
                }, () => {
                    // After SubjectList is updated, find the subject by subject_id
                    this.setWhatWeHelp();
                });
            } else {
                console.log('Subject not available');
            }
        })
        .catch(error => {
            console.log('Error fetching subjects:', error);
        });
  }

  setWhatWeHelp = () => {
    const { subject_id, SubjectList } = this.state;
    
    // Find the subject with the matching subject_id
    const selectedSubject = SubjectList.find(subject => subject.id === parseInt(subject_id));
    
    if (selectedSubject) {
        // Set the subject name in the what_we_help state
        this.setState({ what_we_help: selectedSubject.title }, () => {
            console.log("Subject name set in what_we_help:", this.state.what_we_help);  // Debugging log
        });
    } else {
        console.log('Subject not found in SubjectList');
    }
}


  validate = () => {
    let nameError = '';
    let emailError = '';
    let numberError = '';
    let messageError = '';
    let what_we_helpError = '';

    if (!this.state.name) {
      nameError = "Please Enter Name"
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Please Enter Email Address.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid Email Address.";
      }
    }

    if (!this.state.number) {
      numberError = "Please Enter Contact Number."
    } else {
      if (this.state.number.length <= 7) {
        numberError = "Phone number cannot be less than eight digit"
      }
    }

    if (!this.state.message) {
      messageError = "Please Enter Message."
    }

    // if (this.state.what_we_help == '') {
    //   what_we_helpError = "Please Select What Can We Help You."
    // }

    // if (!this.state.userInput) {
    //   captchError = "Please Enter Captcha Code."
    // } else {
    //   if (parseInt(this.state.userInput, 10) !== expectedAnswer) {
    //     captchError = "Please Enter Valid Captcha."
    //   }
    // }


    if (nameError || emailError || numberError || messageError ) {
      this.setState({
        nameError,
        emailError,
        numberError,
        messageError
      });

      return false;
    }

    console.log('name', this.state.name)
    console.log('number', this.state.number)
    console.log('email', this.state.email)
    console.log('what_we_help', this.state.what_we_help)
    console.log('message', this.state.message)

    return true;
  }


  onSubmit = async () => {

    if (this.validate()) {
      this.setState({
        nameError: null,
        emailError: null,
        numberError: null,
        messageError: null,
        captchError: null
      });
      this.onContact();
    }
  }


  onContact = async () => {
    this.setState({ isLoading: true, isActive: true })
    const recaptchaValue = await this.recaptchaRef.current.executeAsync();
    console.log(recaptchaValue)
    console.log(this.state.what_we_help)
    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("number", this.state.number);
    formData.append("level_name", this.state.level_name);
    formData.append("message", this.state.message);
    formData.append("what_we_help", this.state.what_we_help);
    formData.append("recaptchaToken",recaptchaValue);

    await fetch(Constant.ContactUs, {
      method: "POST",
      body: formData,
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({ isLoading: false, isActive: true })
          window.location.href = Constant.BaseUrl + 'thank-you-contact'
        } else {
          this.setState({ isLoading: false, isActive: true })
        }
      })
  }

  handleChangeName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({ name: result })
  };

  handleChangePhone = event => {
    const result = event.target.value.replace(/[^0-9]/gi, '');
    this.setState({ number: result })
  };

  handleChangeHelp = event => {
    const selectedSubjectId = parseInt(event.target.value);
    const selectedSubject = this.state.SubjectList.find(subject => subject.id === selectedSubjectId);
    
    if (selectedSubject) {
      this.setState({ 
        what_we_help: selectedSubject.title // Store the subject title
      }, () => {
        console.log("Updated subject name:", this.state.what_we_help); // Debugging log
      });
    }else{
      this.setState({ 
        what_we_help: event.target.value // Store the subject title
      },)
    }
  };

  render() {
    const { subject_id, SubjectList, what_we_help } = this.state;

    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner
        fadeSpeed={10}
        text='Message Sending...'
      >
        <section className="contact_us_main">
          <div className="bg-black pb-5" style={{ border: 'none', borderRadius: 60 }}>
            <h1 className="text-center pt-5" style={{ fontWeight: 700, fontSize: 38, color: '#fff' }}>Contact us</h1>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10 ">
                <div className="row mt-5">
                  <div className="col-md-6 form-group">
                    <input type="text" placeholder="Full Name" value={this.state.name}
                      onChange={this.handleChangeName} name="name" className="form-control contactradius" />
                    {!!this.state.nameError && (
                      <text style={{ color: "red", marginLeft: "10px" }}>
                        {this.state.nameError}
                      </text>
                    )}
                  </div>
                  <div className="col-md-6 form-group">
                    <input type="text" placeholder="Phone Number" name="number"
                      value={this.state.number} onChange={this.handleChangePhone} minLength="11"
                      maxLength="10" className="form-control contactradius" />
                    {!!this.state.numberError && (
                      <text style={{ color: "red", marginLeft: "10px" }}>
                        {this.state.numberError}
                      </text>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <input type="text" name="email"
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    } placeholder="Email Address" className="form-control contactradius" />
                  {!!this.state.emailError && (
                    <text style={{ color: "red", marginLeft: "10px" }}>
                      {this.state.emailError}
                    </text>
                  )}
                </div>
                <div className="form-group">
                  <select
                    className="form-control subject-select select_input"
                    value={what_we_help}
                    onChange={this.handleChangeHelp}
                  >
                    {/* Show selected subject if subject_id exists */}
                    {subject_id && SubjectList.find(subject => subject.id === parseInt(subject_id)) ? (
                      <option value={subject_id}>
                        {SubjectList.find(subject => subject.id === parseInt(subject_id))?.title}
                      </option>
                    ) : (
                      <>
                        <option value="">What can we help you with?</option>
                      <option value="ask_a_query">Ask a query</option>
                      <option value="book_math_session">Book Math Session</option>
                      <option value="book_eng_session">Book English session</option>
                      <option value="book_sci_session">Book Science session</option>
                      <option value="book_com_session">Book Computing session</option>
                      <option value="com_exam">Book a session for Competitive exams</option>
                      </>
                    
                    )}

                    {/* Show all subjects if no subject_id */}
                    {!subject_id && SubjectList.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>
                  {!!this.state.what_we_helpError && (
                    <text style={{ color: "red", marginLeft: "10px" }}>
                      {this.state.what_we_helpError}
                    </text>
                  )}
                </div>

                <div className="form-group">
                  <input className="form-control contactradius " onChange={(event) =>
                    this.setState({ level_name: event.target.value })
                  } name="level_name" placeholder="Level Name"  />
                </div>

                <div className="form-group">
                  <textarea className="form-control contactradius pt-3" onChange={(event) =>
                    this.setState({ message: event.target.value })
                  } name="message" placeholder="Type message here..." style={{ height: 250, borderRadius: 25 }}></textarea>
                  {!!this.state.messageError && (
                    <text style={{ color: "red", marginLeft: "10px" }}>
                      {this.state.messageError}
                    </text>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-8">
                    <ReCAPTCHA
                      sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                      size="invisible"
                      ref={this.recaptchaRef}
                    />

                  </div>
                  <div className="col-md-4">
                    <div className="actions">
                      <button
                        style={{ width: '100%' }}
                        type="submit"
                        name="submit"
                        onClick={() => this.onSubmit()}
                        className="lending-btn demo-btn "
                        title="Submit Your Message!"
                      >
                        Send
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-black pb-5" style={{ border: 'none' }}>
          <div className="container" >
            <div className="row">
              <div className="col-md-6 mt-2">
                <p className="contact_head">Build your<br></br><br></br> Child’s Future<br></br><br></br> with us</p>
                <p className="contact_sub mt-5" style={{ width: 300 }}>Unlock limitless potential and shape a brighter tomorrow – Join Education Capsule today!</p>
              </div>
              <div className="col-md-6">
                <img src={contact_img} alt="Contact Image" />
              </div>
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
}

export default ContactUs;